<template>
  <section class="pb-10">
    <!-- Consultas publicadas en expansion panels -->
    <v-row>
      <v-col cols="10">
        <v-card>
          <v-card-text>
            <v-expansion-panels class="expansion-panels-consultas" multiple>
              <consulta-component
                v-for="consulta in consultasOrdenadas"
                :isUaci="true"
                :key="consulta.id"
                :consulta="consulta"
              />
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import ConsultaComponent from "./ConsultaComponent .vue";
import { mapState } from "vuex";

export default {
  name: "ConsultasComponent",
  props: {
    idEtapa: {
      type: Number,
      default: 2,
    },
  },
  components: {
    ConsultaComponent,
  },
  data() {
    return {
      item: null,
      nueva_consulta: "",
      // Mover toda esta data a VUEX cuando se empiece a consumir la API
      consultas: [
        {
          id: 1,
          numero: 1,
          fecha: "2022-11-19 00:00:00",
          estado: 2, // 1: presentada, 2: publicada, 3: rechazada
          consulta:
            "¿Se pueden hacer nuevas ofertas a las viviendas experimentales abovedadas o modificar las ya existentes transformando ventanas en puertas? ¿Se pueden demoler las paredes interiores?",
          respuestas: [
            {
              id: 1,
              fecha: "2022-11-20 00:00:00",
              responsable:
                'UCP - Hospital Nacional "San Juan de Dios", San Miguel',
              respuesta:
                "Es permitido mientras no se dañe la estructura arquitectónica del lugar.",
            },
          ],
        },
        {
          id: 2,
          numero: 2,
          fecha: "2022-11-22 00:00:00",
          estado: 3, // 1: presentada, 2: publicada, 3: rechazada
          consulta:
            "¿Se pueden hacer nuevas ofertas a las viviendas experimentales abovedadas o modificar las ya existentes transformando ventanas en puertas? ¿Se pueden demoler las paredes interiores?",
          respuestas: [
            {
              id: 1,
              fecha: "2022-11-22 00:00:00",
              responsable:
                'UCP - Hospital Nacional "San Juan de Dios", San Miguel',
              respuesta:
                "Su consulta o procede ya que es una pregunta publicada repetida, ver pregunta publicada #1",
            },
          ],
        },
      ],
    };
  },
  methods: {
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.item = data;
      this.consultas = data.consultas;
    },
  },
  computed: {
    consultasOrdenadas() {
      //  Ordenar consultas mostrando las mas recientes primero
      return this.consultas.sort((a, b) => {
        return new Date(b.fecha) - new Date(a.fecha);
      });
    },
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>
<style lang="scss">
.expansion-panels-consultas {
  .v-expansion-panel-header {
    // Colocar borde en la parte inferior
    // border-bottom: 1px solid #e0e0e0;
    border: 1px solid red;
  }
  .v-expansion-panel {
    .v-expansion-panel-content {
      background-color: var(--v-bgMinsal-base);
    }

    .v-expansion-panel-header {
      border-bottom: 1px solid #e0e0e0 !important;
      padding-left: 10px !important;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none !important;
  }
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
}
</style>
