<template>
  <section>
    <app-card-liquidacion
      v-if="item"
      :title="item.nombre_etapa"
      :fecha_inicio="getFechaFormated(item?.fecha_hora_inicio)"
      :fecha_fin="getFechaFormated(item?.fecha_hora_fin)"
      :dias="getDays(item?.fecha_hora_inicio, item?.fecha_hora_fin)"
      :observaciones="item?.observacion"
    >
    </app-card-liquidacion>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ContratacionComponent",
  props: {
    idEtapa: {
      type: Number,
      default: 14,
    },
  },
  data: () => ({
    item: null,
  }),
  methods: {
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.item = data.etapaProceso;
    },
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style></style>
