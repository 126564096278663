<template>
  <section>
    <app-card-liquidacion
      v-if="item"
      :title="item.etapaProceso.nombre_etapa"
      :fecha_inicio="getFechaFormated(item?.etapaProceso.fecha_hora_inicio)"
      :fecha_fin="getFechaFormated(item?.etapaProceso.fecha_hora_fin)"
      :dias="
        getDays(
          item?.etapaProceso.fecha_hora_inicio,
          item?.etapaProceso.fecha_hora_fin
        )
      "
      :observaciones="item?.etapaProceso.observacion"
    >
      <template #content>
        <p class="mb-0">Recurso de revisión</p>

        <DataTable
          :headers="headers"
          :items="item.documentos"
          :show_actions="true"
          :actions="actions"
          :tiene_paginacion="false"
          @downloadDocumento="downloadDocumento"
        >
          <template v-slot:[`item.nombre_documento`]="{ item }">
            {{ shortText(item.nombre_documento) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ getFechaFormated(item.created_at) }}
          </template>
        </DataTable>
      </template>
    </app-card-liquidacion>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "RecursoRevisionComponent",
  props: {
    idEtapa: {
      type: Number,
      default: 11,
    },
  },
  components: {
    DataTable,
  },
  data: () => ({
    item: null,
    headers: [
      {
        text: "Nombre del documento",
        value: "nombre_documento",
        sortable: true,
      },
      {
        text: "Fecha",
        value: "created_at",
        sortable: true,
      },
      {
        text: "Opciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Descargar documento",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-download",
        },
        eventName: "downloadDocumento",
      },
    ],
  }),
  methods: {
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.item = data;
    },
    getFechaFormated(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    getDays(fecha_inicio, fecha_fin) {
      return moment(fecha_fin).diff(moment(fecha_inicio), "days");
    },
    shortText(text) {
      if (text.length < 30) return text;
      else {
        return text.slice(0, 30) + "..." + "pdf";
      }
    },
    async descargarDocumento(urlDocument) {
      const response = await this.services.Paac.downloadDocumentoProcesoCompra(
        urlDocument
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "RecursoRevision");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    downloadDocumento({ ruta_documento }) {
      this.descargarDocumento(ruta_documento);
    },
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style></style>
