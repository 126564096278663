<template>
  <section class="justify-start">
    <v-card>
      <v-card-text>
        <v-card-title>
          <p class="secondary--text">Detalle general del contrato</p>
        </v-card-title>
        <v-card-text>
          <p class="text-subtitle-1 font-weight-bold">Juan José López López</p>
          <p class="mb-0">Administrador de contrato</p>
          <p class="text-subtitle-1 font-weight-bold">Juan José López López</p>
          <p class="mb-0">Número de contrato</p>
          <p class="text-subtitle-1 font-weight-bold">C-2022-21654</p>
          <p class="mb-0">Número de contrato interno</p>
          <p class="text-subtitle-1 font-weight-bold">C-LG-5165</p>
          <p class="mb-0">Fecha y hora de contratación</p>
          <p class="text-subtitle-1 font-weight-bold">06/09/2021 12:00:00</p>
          <p class="mb-0">Monto adjudicado</p>
          <p class="text-subtitle-1 font-weight-bold">10,000.00</p>
          <p class="mb-0">Justificación de la compra</p>
          <p class="text-subtitle-1 font-weight-bold">
            Se entregará en X instalación, ya que se comparte almacenamiento con
            X unidad
          </p>
          <p class="mb-0">Contrato</p>
          <p class="text-subtitle-1 font-weight-bold">
            <v-icon class="mr-2">mdi-file-account</v-icon>
            <span>C-LG5165</span>
          </p>
        </v-card-text>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "DetalleGralContratoComponent",
};
</script>

<style></style>
