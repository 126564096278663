<template>
  <section>
    <app-card-liquidacion
      v-if="item"
      :title="item?.nombre_etapa"
      :fecha_inicio="getFechaFormated(item?.fecha_hora_inicio)"
      :fecha_fin="getFechaFormated(item?.fecha_hora_fin)"
      :dias="getDays(item?.fecha_hora_inicio, item?.fecha_hora_fin)"
      :observaciones="item?.observacion"
    >
    </app-card-liquidacion>
  </section>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "RecepcionOfertasComponent",
  props: {
    idEtapa: {
      type: Number,
      default: 4,
    },
  },
  data: () => ({
    item: null,
  }),
  methods: {
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.item = data.etapaProceso;
    },
    getFechaFormated(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    getDays(fecha_inicio, fecha_fin) {
      return moment(fecha_fin).diff(moment(fecha_inicio), "days");
    },
    shortText(text) {
      if (text.length < 30) return text;
      else {
        return text.slice(0, 30) + "..." + "pdf";
      }
    },
    async descargarDocumento(urlDocument) {
      const response = await this.services.Paac.downloadDocumentoProcesoCompra(
        urlDocument
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "AdendasEnmiendas");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style></style>
