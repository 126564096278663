<template>
  <section>
    <app-card-liquidacion
      title="Cierre"
      fecha_inicio="29/04/2022 00:00"
      fecha_fin="29/04/2022 00:00"
      dias="4"
      observaciones="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem
            cupiditate vel enim facere perferendis quasi doloremque odit animi
            officiis dignissimos, iusto vitae omnis. Officia perferendis, nam
            voluptatem saepe atque eos."
    >
    </app-card-liquidacion>
  </section>
</template>

<script>
export default {
  name: "CierreComponent",
};
</script>

<style></style>
