<template>
  <section>
    <app-card-liquidacion
      v-if="item"
      :title="item.nombre_etapa"
      :fecha_inicio="getFechaFormated(item?.fecha_hora_inicio)"
      :fecha_fin="getFechaFormated(item?.fecha_hora_fin)"
      :dias="getDays(item?.fecha_hora_inicio, item?.fecha_hora_fin)"
      :observaciones="item?.observacion"
    >
      <template #content>
        <p class="mb-0">Adendas</p>
        <p
          class="text-subtitle-1 font-weight-bold"
          v-if="item.nombre_documento"
        >
          <v-icon class="mr-2">mdi-file-outline</v-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="text-subtitle-2 ml-5">{{
                shortText(item?.nombre_documento)
              }}</span>
            </template>
            <span>{{ item?.nombre_documento }}</span>
          </v-tooltip>
          <v-icon class="ml-2" @click="descargarDocumento(item.ruta_documento)">
            mdi-download</v-icon
          >
          <span> {{ getFechaFormatedAM(item.fecha_documento) }}</span>
        </p>
        <p class="text-subtitle-1 font-weight-bold" v-else>
          No se encontró documento
        </p>
      </template>
    </app-card-liquidacion>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AdendasEnmiendasComponent",
  props: {
    idEtapa: {
      type: Number,
      default: 3,
    },
  },
  data: () => ({
    item: null,
  }),
  methods: {
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.item = data.etapaProceso;
    },

    async descargarDocumento(urlDocument) {
      const response = await this.services.Paac.downloadDocumentoProcesoCompra(
        urlDocument
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "AdendasEnmiendas");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style></style>
