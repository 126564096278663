<template>
  <section>
    <app-card-liquidacion
      v-if="item"
      :title="item.etapaProceso?.nombre_etapa"
      :fecha_inicio="getFechaFormated(item?.etapaProceso?.fecha_hora_inicio)"
      :fecha_fin="getFechaFormated(item?.etapaProceso?.fecha_hora_fin)"
      :dias="
        getDays(
          item?.etapaProceso?.fecha_hora_inicio,
          item?.etapaProceso?.fecha_hora_fin
        )
      "
      :observaciones="item?.etapaProceso?.observacion"
    >
      <template #content>
        <p class="mb-0">Ofertas presentadas</p>
        <DataTable
          :headers="headers"
          :items="item.ofertas"
          :show_actions="true"
          :actions="actions"
          :tiene_paginacion="false"
          @showApertura="showApertura"
        >
        </DataTable>
      </template>
    </app-card-liquidacion>
    <app-dialog
      v-if="detalleApertura"
      v-model="value"
      title="Oferta presentada"
      :maxWidth="1000"
    >
      <template #content>
        <v-row>
          <v-col cols="12" sm="5">
            <v-row>
              <v-col cols="12">
                <span>Nombre del proveedor</span>
                <p class="font-weight-black">
                  {{
                    detalleApertura.ProveedorParticipacionObs[0]?.Proveedor
                      ?.nombre
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>OBS</span>
                <p class="font-weight-black">
                  {{
                    detalleApertura.ProcesoOb.obs.codigo +
                    " - " +
                    detalleApertura.ProcesoOb?.obs?.nombre
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Articulo</span>
                <p class="font-weight-black">{{ detalleApertura.articulo }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>OBS</span>
                <p class="font-weight-black">
                  {{ detalleApertura?.Presentacion?.nombre }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Especificación</span>
                <p class="font-weight-black">
                  {{ detalleApertura.especificacion }}
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="7">
            <v-card elevation="5">
              <v-card-text>
                <v-card-title style="color: #404a7a"
                  >Formulario de oferta</v-card-title
                >
                <v-row>
                  <v-col cols="8">
                    <v-file-input
                      outlined
                      label="Oferta"
                      prepend-icon=""
                      prepend-inner-icon="mdi-paperclip"
                      disabled
                      v-model="documento"
                    >
                      <template v-slot:append-outer>
                        <v-icon @click="obtenerDocumentoBases" color="green"
                          >mdi-file-download</v-icon
                        >
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <MoneyInput
                      ref="mi_precio_unitario"
                      label="Precio unitario"
                      :value="precioCmpt"
                      :disabled="true"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      label="Cantidad"
                      min="0"
                      type="number"
                      :value="
                        detalleApertura.ProveedorParticipacionObs[0].cantidad
                      "
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <MoneyInput
                      ref="mi_total"
                      label="Total oferta"
                      :value="totalCmpt"
                      :disabled="true"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <div>
          <v-btn color="secondary" @click="value = false" class="mr-4" outlined>
            Cerrar
          </v-btn>
        </div>
      </template>
    </app-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import MoneyInput from "@/components/MoneyTextFieldComponent.vue";

import { mapState } from "vuex";

export default {
  name: "AperuraOfertaComponent",
  props: {
    idEtapa: {
      type: Number,
      default: 5,
    },
  },
  components: {
    DataTable,
    MoneyInput,
  },
  data: () => ({
    value: true,
    documento: null,
    headers: [
      {
        text: "Ofertante",
        value: "ofertante",
        sortable: true,
      },
      {
        text: "Monto Total",
        value: "monto_total",
        sortable: true,
      },
      {
        text: "Opciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Ver",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye-outline",
        },
        eventName: "showApertura",
      },
    ],
    item: null,
    detalleApertura: null,
    document_path: null,
    document_name: null,
  }),
  methods: {
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.item = data;
    },

    async descargarDocumento(urlDocument) {
      const response = await this.services.Paac.downloadDocumentoProcesoCompra(
        urlDocument
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "BaseLicitacion");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async getDetalleAperturaOferta(
      id_proceso_compra,
      id_sub_proceso_obs,
      id_proveedor
    ) {
      const { data } = await this.services.Paac.getDetalleAperturaOferta(
        id_proceso_compra,
        id_sub_proceso_obs,
        id_proveedor
      );
      this.detalleApertura = data;

      this.document_path = data.ProveedorParticipacionObs[0].url;
      this.document_name =
        data.ProveedorParticipacionObs[0]?.nombre_documento.split("-")[0];
      this.value = true;
    },
    showApertura({ id_sub_proceso_obs, id_proveedor }) {
      this.getDetalleAperturaOferta(
        this.$route.params.id_proceso,
        id_sub_proceso_obs,
        id_proveedor
      );
    },
    async obtenerDocumentoBases() {
      const response = await this.services.Paac.downloadDocumentoProcesoCompra(
        this.document_path
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "AperturaOferta");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
    precioCmpt() {
      return this.detalleApertura?.ProveedorParticipacionObs[0]
        ?.precio_unitario;
    },
    totalCmpt() {
      return this.detalleApertura?.ProveedorParticipacionObs[0]?.total;
    },
  },
  /*
    Se creo este watch para actualizar el valor del input de precio unitario y total,
    ya que al actualizar el valor de la variable precioCmpt y totalCmpt, no se actualizaba,
    por logica del component
  */
  watch: {
    precioCmpt(value) {
      if (value) {
        this.$refs?.mi_precio_unitario?.updateValue(
          value
          );
      }
    },
    totalCmpt(value) {
      if (value) {
        this.$refs?.mi_total?.updateValue(
          value
          );
      }
    },
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style></style>
