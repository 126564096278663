<template>
  <section>
    <app-card-liquidacion
      v-if="item.etapaProceso"
      :title="item.etapaProceso.nombre_etapa"
      :fecha_inicio="getFechaFormated(item?.etapaProceso.fecha_hora_inicio)"
      :fecha_fin="getFechaFormated(item?.etapaProceso.fecha_hora_fin)"
      :dias="
        getDays(
          item?.etapaProceso.fecha_hora_inicio,
          item?.etapaProceso.fecha_hora_fin
        )
      "
      :observaciones="item?.etapaProceso.observacion"
    >
      <template #content>
        <p class="mb-0">Evaluación</p>
        <v-data-table
          :headers="headers"
          :items="item.newEvaluaciones"
          :items-per-page="5"
          :item-class="itemRowBackground"
          hide-default-footer
        ></v-data-table>
      </template>
    </app-card-liquidacion>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapState } from "vuex";

export default {
  name: "EvaluacionOfertaComponent",
  // Puede ser 7 o 12
  props: {
    idEtapa: {
      type: Number,
      default: 7,
    },
  },
  components: {
    DataTable,
  },
  data: () => ({
    headers: [
      {
        text: "Ofertante",
        value: "ofertante",
        align: "center",
        sortable: true,
      },
      {
        text: "Monto Ofertado",
        value: "monto_total",
        align: "center",
        sortable: true,
      },
      {
        text: "Evaluación Técnica",
        value: "evaluacion_tecnica",
        align: "center",
        sortable: true,
      },
      {
        text: "Evaluación Financiera",
        value: "evaluacion_financiera",
        align: "center",
        sortable: true,
      },
    ],
    item: [],
  }),
  methods: {
    itemRowBackground: function (item) {
      return item.ganador ? "winner-evaluation" : null;
    },
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.item = data;
    },
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style>
.winner-evaluation {
  background-color: rgb(137, 238, 151);
}
</style>
