<template>
  <section>
    <app-card-liquidacion
      v-if="items"
      :title="items.etapaProceso.nombre_etapa"
      :fecha_inicio="getFechaFormated(items?.etapaProceso.fecha_hora_inicio)"
      :fecha_fin="getFechaFormated(items?.etapaProceso.fecha_hora_fin)"
      :dias="
        getDays(
          items?.etapaProceso.fecha_hora_inicio,
          items?.etapaProceso.fecha_hora_fin
        )
      "
      :observaciones="items?.etapaProceso.observacion"
    >
      <template #content>
        <p class="mb-0">Ofertas presentadas:</p>
        <DataTable
          :headers="headers"
          :items="items.ganadores"
          @sendItem="sendItem"
        > 
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="secondary" @click="sendItem(item.id, item.id_proveedor)"
              >mdi-eye
            </v-icon>
          </template>
        </DataTable>
      </template>
    </app-card-liquidacion>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapState } from "vuex";

export default {
  name: "ResultadosComponent",
  //Puede ser 10 o 13
  props: {
    idEtapa: {
      type: Number,
      default: 10,
    },
  },
  components: {
    DataTable,
  },
  data: () => ({
    id: null,
    id_proveedor: null,
    headers: [
      {
        text: "Ofertante",
        value: "ofertante",
        sortable: true,
      },
      {
        text: "Monto Total",
        value: "monto_total",
        sortable: true,
      },
      {
        text: "Opciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    // actions: [
    //   {
    //     tooltip: {
    //       text: "Ver Resultado",
    //       color: "blueGrayMinsal",
    //     },
    //     icon: {
    //       color: "secondary",
    //       name: "mdi-eye-outline",
    //     },
    //     eventName: "showResult",
    //   },
    // ],
    items: null,
  }),
  methods: {
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.items = data;
    },
    sendItem(id, id_proveedor){
      this.id = id; //id_proceso_compra
      this.id_proveedor = id_proveedor;
      this.$router.push({
          name: "oferta-presentada",
          params: { id: this.id, id_proveedor: this.id_proveedor },
        });
    }
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style></style>
