<template>
  <v-expansion-panel ref="expansionpanel">
    <v-expansion-panel-header color="bgMinsal">
      <v-row>
        <v-col cols="12" class="py-0 my-0 px-0">
          <p class="mb-1">
            <span class="secondary--text">{{ consulta.proveedor_nombre }}</span>
            <span class="grey--text ml-2">{{
              fechaHaceTiempo(consulta.fecha)
            }}</span>
            <v-chip
              label
              :color="consulta.color"
              small
              class="white--text ml-3"
            >
              <span v-if="consulta.id_estado == 1">Presentada</span>
              <span v-if="consulta.id_estado == 2">Rechazada</span>
              <span v-if="consulta.id_estado == 3">Publicada</span>
            </v-chip>
          </p>
        </v-col>
        <v-col
          cols="12"
          :class="
            consulta.id_estado == 1 || consulta.id_estado == 3
              ? 'pt-1 pb-2 pl-0'
              : 'pt-1 pb-8 pl-0'
          "
          >{{ consulta.consulta }}</v-col
        >
        <!-- <v-col
          v-if="consulta.id_estado == 1 || consulta.id_estado == 3"
          cols="12"
          class="pt-0 pl-0 pr-0 pb-3"
        >
          <v-btn
            v-if="
              consulta.id_estado == 3 || (consulta.id_estado == 1 && isUaci)
            "
            text
            @click.native.stop
            @click="mostrarResponder"
            ><v-icon>mdi-arrow-left</v-icon> Responder</v-btn
          >
          <v-btn
            v-if="consulta.id_estado == 1 && isUaci"
            text
            @click.native.stop
            @click="mostrarEditar"
            ><v-icon>mdi-pencil</v-icon> Editar</v-btn
          >
        </v-col> -->
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pt-5">
      <consulta-respuesta-uaci-component
        v-for="respuesta in consulta.respuestas"
        :key="respuesta.id"
        :id="respuesta.id"
        :responsable="
          respuesta.institucion_nombre ?? respuesta.proveedor_nombre
        "
        :fecha="respuesta.fecha"
        :respuesta="respuesta.consulta"
        :estado="consulta.id_estado"
      >
      </consulta-respuesta-uaci-component>

      <v-row
        class="mt-0"
        v-if="
          (consulta.id_estado == 3 || (consulta.id_estado == 1 && isUaci)) &&
          tipoFormulario == 1
        "
      >
        <v-col cols="7">
          <v-textarea
            :error-messages="respuestaErrors"
            v-model="respuesta"
            label="Comentar"
            placeholder="Agregue una respuesta"
            outlined
            rows="3"
          ></v-textarea>
        </v-col>
        <v-col cols="2" v-if="consulta.id_estado == 3">
          <v-btn
            outlined
            class="mt-8"
            @click="responder()"
            :loading="isLoadingAgregar"
            color="secondary"
          >
            Agregar
          </v-btn>
        </v-col>
        <v-col
          cols="2"
          v-if="consulta.id_estado == 1 && isUaci"
          class="py-0 my-0"
        >
          <div>
            <v-btn
              outlined
              :loading="isLoadingAgregar"
              :disabled="isLoadingRechazar"
              class="mt-4"
              width="100%"
              color="green"
              @click="responder(3)"
            >
              Publicar
            </v-btn>
            <v-btn
              outlined
              :loading="isLoadingRechazar"
              :disabled="isLoadingAgregar"
              class="mt-4"
              width="100%"
              color="red"
              @click="responder(2)"
            >
              Rechazar
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="mt-0"
        v-if="
          (consulta.id_estado == 3 || consulta.id_estado == 1) &&
          tipoFormulario == 2
        "
      >
        <v-col cols="7">
          <v-textarea
            :error-messages="editadoErrors"
            v-model="editado"
            label="Comentar"
            placeholder="Agregue una respuesta"
            outlined
            rows="3"
          ></v-textarea>
        </v-col>
        <v-col cols="2">
          <v-btn outlined class="mt-8" color="secondary" @click="editar()">
            Editar
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ConsultaRespuestaUaciComponent from "../ConsultaRespuestaUaciComponent.vue";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ConsultaComponent",
  components: {
    ConsultaRespuestaUaciComponent,
  },
  props: {
    consulta: {
      type: Object,
      required: true,
    },
    isUaci: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editado: "",
      comentario: "",
      tipoFormulario: 0, // 0: Sin formulario 1: Consulta, 2: Editar
      respuesta: null, // Esta es la respuesta
    };
  },
  validations: {
    respuesta: {
      requiredIf: requiredIf(function () {
        return this.tipoFormulario == 1;
      }),
    },
    editado: {
      requiredIf: requiredIf(function () {
        return this.tipoFormulario == 2;
      }),
    },
  },
  methods: {
    ...mapActions("etapaConsultasStore", [
      "responderConsulta",
      "editarConsulta",
    ]),
    async responder(id_estado = null) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const response = await this.responderConsulta({
        consulta: this.respuesta,
        id_consulta: this.consulta.id,
        id_estado: id_estado,
      });
      if (response.status == 201) {
        this.respuesta = "";
        // Reiniciar validaciones
        this.$v.$reset();
      }
    },
    async editar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const response = await this.editarConsulta({
        consulta: this.editado,
        id_consulta: this.consulta.id,
      });
      if (response.status == 200) {
        this.editado = "";
        // Reiniciar validaciones
        this.$v.$reset();
      }
    },
    mostrarCajaRespuesta() {
      this.mostrar_caja_texto = true;
    },
    ocultarCajaRespuesta() {
      this.mostrar_caja_texto = false;
    },
    mostrarResponder() {
      if (!this.$refs.expansionpanel.isActive) {
        this.$refs.expansionpanel.isActive = true;
      }
      this.tipoFormulario = 1;
    },
    mostrarEditar() {
      if (!this.$refs.expansionpanel.isActive) {
        this.$refs.expansionpanel.isActive = true;
      }
      this.tipoFormulario = 2;
    },
  },
  computed: {
    ...mapState("etapaConsultasStore", [
      "isLoadingAgregar",
      "isLoadingRechazar",
    ]),
    respuestaErrors() {
      const errors = [];
      if (!this.$v.respuesta.$dirty) return errors;
      !this.$v.respuesta.required && errors.push("El campo es requerido");
      return errors;
    },
    editadoErrors() {
      const errors = [];
      if (!this.$v.editado.$dirty) return errors;
      !this.$v.editado.required && errors.push("El campo es requerido");
      return errors;
    },
  },
  created() {
    this.editado = this.consulta.consulta;
  },
};
</script>
<style lang=""></style>
